<template>
    <div class="thirdColumnList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>区域管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>区域名称</span>
      <el-input
        placeholder="请输入区域名称"
        v-model.trim="list.areaName"
      ></el-input>
      <el-button type="primary" @click="onSearch">搜索</el-button>
      <el-button
        type="primary"
        @click="addSecondDialogVisible = true"
        v-if="
          $store.state.powerList.indexOf('activity:area:list:add') !==
          -1
        "
        >添加</el-button
      ><el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
    >
      <el-table-column  prop="areaId" label="区域ID" width="180">
      </el-table-column>
      <el-table-column
        
        prop="activityName"
        label="活动标题"
        width="180"
      >
      </el-table-column>
      <el-table-column
        
        prop="areaName"
        label="区域名称"
        width="180"
      >
      </el-table-column>
      <el-table-column
       
        prop="clickNo"
        label="访问人数"
        width="180"
      >
      </el-table-column>
      <el-table-column
        
        prop="userNo"
        label="注册人数"
        width="180"
      >
      </el-table-column>
      <el-table-column
        
        prop="orderNo"
        label="下单数量"
        width="180"
      >
      </el-table-column>
      <el-table-column  label="操作">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="onEditColunm(row)"
            v-if="
              $store.state.powerList.indexOf(
                'activity:area:list:add'
              ) !== -1
            "
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onDel(row)"
            v-if="
              $store.state.powerList.indexOf(
                'activity:area:list:delete'
              ) !== -1
            "
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="showCode(row)" >生成二维码</el-button>
          <el-button type="primary" size="mini" @click="$router.push('/orderDetails/3?activityId=' + row.activityId +'&areaId='+row.areaId)" >下单详情</el-button>
          <el-button type="primary" size="mini" @click="govipList(row.activityId,row.areaId)" >注册详情</el-button>
          <el-button v-if="$store.state.powerList.indexOf('activity:click:list:select') !== -1" type="primary" size="mini" @click="$router.push('/visitDetails/' + row.activityId +'/' +row.areaId)" >访问详情</el-button>
        </template>
      </el-table-column>
    </el-table>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="this.list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
    <el-dialog
      title="编辑区域"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="区域名称">
          <el-input v-model="onEditForBackList.areaName"></el-input>
        </el-form-item>
        <el-form-item label="区域简介">
          <el-input type="textarea" v-model="onEditForBackList.summary" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditForBackOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加区域"
      :visible.sync="addSecondDialogVisible"
      width="30%"
    >
      <el-form ref="form">
        <el-form-item label="区域名称">
          <el-input
            placeholder="请输入区域名称"
            v-model="addForBack.areaName"
          ></el-input>
          <el-form-item label="区域简介">
            <el-input
            type="textarea"
              v-model="addForBack.summary"
              placeholder="请输入区域简介"
              :rows="4"
            ></el-input>
          </el-form-item>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addSecondDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onaddForBackOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input style="width:300px" v-model.trim="QRcodeSize" placeholder="请输入生成的二维码大小"></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color:red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { selectActivityArea,addActivityArea,deleteActivityArea,selectErCode} from "../../../api/activePage";
    export default {
  name: "regionalManagement",
        props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data(){
    return{
      tableData: [],
      list:{
        currentPage: 1,
        pageSize: 10,
        activityId:this.id,
        areaName:''
      },
      addForBack:{
        areaId:'' || 0,
        activityId:this.id,
        areaName:'',
        summary:''
      },
      addSecondDialogVisible:false,
      editDialogVisible:false,
      onEditForBackList:{},
      codeVisible:false,
      imgUrl:'',
      QRcodeSize:'',
      areaId:''
    }
  },
  created() {
    this.getselectForBackList();
  },
  methods:{
    async getselectForBackList(){
        const { data } = await selectActivityArea(this.list);
        this.tableData = data.list;
    },
    onSearch() {
      this.getselectForBackList();
    //   this.list.areaName = "";
    },
   async onaddForBackOk(){
    if (
        this.addForBack.areaName === "" ||
        this.addForBack.summary === ""
      ) {
        this.$message({
          showClose: true,
          message: "请把数据填写完整!",
          type: "warning",
        });
        return;
      }
      const {data} = await addActivityArea(this.addForBack);
      this.addSecondDialogVisible = false;
      this.getselectForBackList();
    },
    async onDel(row) {
      this.$confirm("是否删除这条栏目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await deleteActivityArea({
            areaId: row.areaId,
          });

          if (data.code === 0) {
            this.getselectForBackList();

            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: "删除失败",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onEditColunm(row) {
      this.onEditForBackList = row;
      this.editDialogVisible = true;
    },
    async onEditForBackOk(){
        console.log(this.onEditForBackList)
        const {areaId,activityId,areaName,summary} = this.onEditForBackList;
        if (
            areaName === "" ||
            summary === ""
      ) {
        this.$message({
          showClose: true,
          message: "请把数据填写完整!",
          type: "warning",
        });
        return;
      }
      const { data } = await addActivityArea({areaId,activityId,areaName,summary});
      if (data.code === 0) {
        this.$message({
          showClose: true,
          message: "编辑成功",
          type: "success",
        });
      } else {
        this.$message({
          showClose: true,
          message: "编辑失败请稍后重试",
          type: "warning",
        });
      }
      this.editDialogVisible = false;
    },
    handleSizeChange(sixe) {
      this.list.pageSize = sixe;
      this.getselectForBackList();
    },
    handleCurrentChange(Current) {
      this.list.currentPage = Current;
      this.getselectForBackList();
    },
    showCode(row) {
      this.codeVisible = true
      this.imgUrl = ''
      this.areaId = row.areaId
    },
    async generateQRcode() {
      this.imgUrl = ''
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await selectErCode({
            areaId	: this.areaId,
          widthSize: this.QRcodeSize
        })
        this.imgUrl = data.msg
      }

    },
    govipList(activityId,areaId){
      this.$router.push({name:'vipList',query:{activityId,areaId}})
    }
  }
    }
</script>

<style lang="less" scoped>

.thirdColumnList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 500px;
      margin: 0 30px;
    }
  }
  /deep/.el-table {
    margin-top: 50px;
  }
  .btn {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>